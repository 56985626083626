import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
// import { ProjectCard } from "./ProjectCard";
// import projImg1 from "../assets/img/project-img1.png";
// import projImg2 from "../assets/img/project-img2.png";
// import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import bestTeam from '../assets/projectachievment/bestteam.png'
import maddingGo from '../assets/img/madinggo.png'
import videoSweetHome from '../assets/projectachievment/sweethome.mp4'
import videoDataEngine from '../assets/projectachievment/dataengineapp.mp4'
import report from '../assets/projectachievment/report.pdf'

import sertificate from '../assets/projectachievment/sertifikat.jpg'

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

    // const projects = [
    //     {
    //         title: "Business Startup",
    //         description: "Design & Development",
    //         imgUrl: projImg1,
    //     },
    //     {
    //         title: "Business Startup",
    //         description: "Design & Development",
    //         imgUrl: projImg2,
    //     },
    //     {
    //         title: "Business Startup",
    //         description: "Design & Development",
    //         imgUrl: projImg3,
    //     },
    //     {
    //         title: "Business Startup",
    //         description: "Design & Development",
    //         imgUrl: projImg1,
    //     },
    //     {
    //         title: "Business Startup",
    //         description: "Design & Development",
    //         imgUrl: projImg2,
    //     },
    //     {
    //         title: "Business Startup",
    //         description: "Design & Development",
    //         imgUrl: projImg3,
    //     },
    // ];

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col size={12}>
                        <TrackVisibility>
                            {({ isVisible }) =>
                                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                                    <h2>Projects & Achievement</h2>
                                    <p>Here are a few my projects and achievements, where I utilized JavaScript, React, NextJS, GraphQL and NodeJS for both frontend and backend development</p>
                                    <Tab.Container id="projects-tabs" defaultActiveKey="first">
                                        <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">Tab 1</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Tab 2</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Tab 3</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                                            <Tab.Pane eventKey="first">
                                                <Row>
                                                    {/* {
                                                        projects.map((project, index) => {
                                                            return (
                                                                <ProjectCard
                                                                    key={index}
                                                                    {...project}
                                                                />
                                                            )
                                                        })
                                                    } */}
                                                    <Col className="d-flex align-items-center order-2 order-sm-1" size={12} sm={6} >
                                                        <p>
                                                            I have worked on several projects at Ganesha Operations, including product sales websites, admin websites, quiz websites, and one of them being the creation of this blog using Next.js and GraphQL.</p>
                                                    </Col>
                                                    <Col size={12} sm={6} className="order-1 order-sm-2" >
                                                        <a rel="noreferrer" href="https://blog-go-three.vercel.app/" target="_blank" >
                                                            <div className="proj-imgbx" style={{ height: '384px' }}>
                                                                <img src={maddingGo} alt="madingGo" style={{ height: '100%' }} />
                                                                <div className="proj-txtx" style={{ color: 'white' }}>
                                                                    <h4>Click to view project</h4>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="second">
                                                <Row>
                                                    <Col className="d-flex justify-content-center align-items-center order-2 order-sm-1" size={12} sm={6}>
                                                        <p>
                                                            This preview showcases the web app I'm developing at Telkom Indonesia. I've designed a frontend section to visually organize startup and investor data specific to Telkom Indonesia.</p>
                                                    </Col>
                                                    <Col size={12} sm={6} className="order-1 order-sm-2" >
                                                        <div className="">
                                                            <video controls width='100%' height='400px' >
                                                                <source src={videoDataEngine} type="video/mp4" />
                                                                your browser is not supported
                                                            </video>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="third">
                                                <Row>
                                                    <Col size={12} sm={6} className="" >
                                                        <div className="proj-imgbxx" style={{ height: '384px' }}>
                                                            <img src={bestTeam} alt="best team" style={{ height: '100%' }} />
                                                            <div className="proj-txtxx">
                                                                <h4>Best Team</h4>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col className="d-flex align-items-center" size={12} sm={6} >
                                                        <p>In the bootcamp's final phase, my team won the "Best Team" category for our outstanding project, outperforming all other teams.</p>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center align-items-center order-4 order-sm-3" size={12} sm={6}>
                                                        <p>This is my certificate for successfully completing the entire bootcamp program. And this is my student <span><a href={report}>report</a></span></p>
                                                    </Col>
                                                    <Col size={12} sm={6} className="order-3 order-sm-4 " >
                                                        <div className="proj-imgbxx" style={{ height: '384px' }} >
                                                            <img src={sertificate} alt="sertifikat" style={{ height: '100%' }} />
                                                        </div>
                                                    </Col>
                                                    <Col size={12} sm={6} className="order-5">
                                                        <div className="">
                                                            <video controls width='100%' >
                                                                <source src={videoSweetHome} type="video/mp4" />
                                                                your browser is not supported
                                                            </video>
                                                        </div>
                                                    </Col>
                                                    <Col className="d-flex justify-content-center align-items-center order-last" size={12} sm={6} >
                                                        <p>This preview highlights the web app I contributed to during Bootcamp. In my backend role, I created REST APIs for both web and mobile. The website functions as a marketplace, allowing users to easily order home interior services.</p>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>}
                        </TrackVisibility>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" alt="background" src={colorSharp2}></img>
        </section>
    )
}
