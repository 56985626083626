import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import whatsapp from "../assets/img/whatsapp.svg";
import email from "../assets/img/envelope.svg";

const NavBar = () => {
  const [activeLink, setActiveLink] = useState("");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              href="#home"
              className={
                activeLink === "home"
                  ? "active navbar-link"
                  : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("home")}
              style={{ textShadow: 'rgb(14, 12, 5) 1px 0 1px' }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#skills"
              className={
                activeLink === "skills"
                  ? "active navbar-link"
                  : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("skills")}
              style={{ textShadow: 'rgb(14, 12, 5) 1px 0 1px' }}
            >
              Skills
            </Nav.Link>
            <Nav.Link
              href="#projects"
              className={
                activeLink === "projects"
                  ? "active navbar-link"
                  : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("projects")}
              style={{ textShadow: 'rgb(14, 12, 5) 1px 0 1px' }}
            >
              Projects
            </Nav.Link>
          </Nav>
          <span className="navbar-text">
            <div className="social-icon">
              <a style={{ backgroundColor: scrolled ? '' : 'grey' }} href="https://www.linkedin.com/in/bayu-firnanda/" rel="noreferrer" target="_blank">
                <img src={navIcon1} alt="my linkendin" />
              </a>
              <a style={{ backgroundColor: scrolled ? '' : 'grey' }} rel="noreferrer" href="https://api.whatsapp.com/send/?phone=%2B6288291383363&text&type=phone_number&app_absent=0" target="_blank" >
                <img src={whatsapp} alt="whatsapp" />
              </a>
              <a style={{ backgroundColor: scrolled ? '' : 'grey' }} rel="noreferrer" href="https://mail.google.com/mail/u/0/?fs=1&to=bafirgenius@gmail.com&su=&tf=cm" target="_blank">
                <img src={email} alt="email" />
              </a>
            </div>
            {/* <HashLink to='#connect'> */}
            {/* <button className="vvd">
              <span>Let’s Connect</span>
            </button> */}
            {/* </HashLink> */}
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
