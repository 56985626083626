import { Container, Row, Col } from "react-bootstrap";
// import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/logo.png";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/whatsapp.svg";
import navIcon3 from "../assets/img/envelope.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          {/* <MailchimpForm /> */}
          <Col size={12} sm={6}>
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon" >
              <a style={{ backgroundColor: 'grey' }} rel="noreferrer" href="https://www.linkedin.com/in/bayu-firnanda/" target="_blank"><img src={navIcon1} alt="Icon" /></a>
              <a style={{ backgroundColor: 'grey' }} rel="noreferrer" href="https://mail.google.com/mail/u/0/?fs=1&to=bafirgenius@gmail.com&su=&tf=cm" target="_blank"><img src={navIcon2} alt="Icon" /></a>
              <a style={{ backgroundColor: 'grey' }} rel="noreferrer" href="https://mail.google.com/mail/u/0/?fs=1&to=bafirgenius@gmail.com&su=&tf=cm" target="_blank"><img src={navIcon3} alt="Icon" /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
