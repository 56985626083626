import React from "react";
import 'react-multi-carousel/lib/styles.css';
// import meter1 from '../assets/img/meter1.svg'
// import meter2 from '../assets/img/meter2.svg'
// import meter3 from '../assets/img/meter3.svg'
import colorSharp from '../assets/img/color-sharp.png'
import Carousel from 'react-multi-carousel';
import reactIcon from '../assets/skill/react.png'
import jsIcon from '../assets/skill/javascript.png'
import nextjsIcon from '../assets/skill/nextjs.png'
import htmlIcon from '../assets/skill/html.png'
import cssIcon from '../assets/skill/css.png'
import nodeIcon from '../assets/skill/nodejs.png'
import mongoIcon from '../assets/skill/mongodb.png'
import githubIcon from '../assets/skill/github.png'
import aiIcon from '../assets/skill/ai.png'
import apacheIcon from '../assets/skill/apache2.png'
import awsIcon from '../assets/skill/aws.png'
import chartjsIcon from '../assets/skill/chartjs.png'
import gitIcon from '../assets/skill/git.png'
import gitlabIcon from '../assets/skill/gitlab.png'
import figmaIcon from '../assets/skill/figma.png'
import graphqlIcon from '../assets/skill/graphql.png'
import herokuIcon from '../assets/skill/heroku.png'
import jestIcon from '../assets/skill/jest.png'
import muiIcon from '../assets/skill/mui.png'
import mysqlIcon from '../assets/skill/mysql.png'
import nginxIcon from '../assets/skill/nginx.png'
import photoshopIcon from '../assets/skill/photoshop.png'
import postgreSql from '../assets/skill/postgreSQL.png'
import reduxIcon from '../assets/skill/redux.png'
import sequelizeIcon from '../assets/skill/sequelize.png'
import talwindIcon from '../assets/skill/talwind.png'
import vercelIcon from '../assets/skill/vercel.png'
import jwtIcon from '../assets/skill/jwt.png'
import bootstrapIcon from '../assets/skill/bootstrap.png'


// import { Image } from 'react-bootstrap'

const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <section className="skill" id="skills">
            <div className="container">
                <div className="row" >
                    <div className="col-12" >
                        <div className="skill-bx wow zoomIn">
                            <h2>Skills</h2>
                            <p>
                                I am proficient in a range of technologies, including ReactJS, Next.js, JavaScript, Node.js, GraphQL, MongoDB, Sequelize, ProgreSQL, Midtrans, HTML5, CSS3, and more.
                            </p>
                            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider" autoPlay={true} >
                                <div className="item" >
                                    <img src={jsIcon} alt="Javascript" />
                                    {/* <Image src={reactIcon} roundedCircle /> */}
                                    <h5>Javascript</h5>
                                </div>
                                <div className="item" >
                                    <img src={reactIcon} alt="React" />
                                    <h5>ReactJS</h5>
                                </div>
                                <div className="item" >
                                    <img src={nextjsIcon} alt="Next" />
                                    <h5>NextJS</h5>
                                </div>
                                <div className="item" >
                                    <img src={htmlIcon} alt="Html" />
                                    <h5>HTML 5</h5>
                                </div>
                                <div className="item" >
                                    <img src={cssIcon} alt="CSS" />
                                    <h5>CSS 3</h5>
                                </div>
                                <div className="item" >
                                    <img src={nodeIcon} alt="NodeJs" />
                                    <h5>NodeJs</h5>
                                </div>
                                <div className="item" >
                                    <img src={mongoIcon} alt="MongoDB" />
                                    <h5>MongoDB</h5>
                                </div>
                                <div className="item" >
                                    <img src={mysqlIcon} alt="MySQL" />
                                    <h5>MySQL</h5>
                                </div>
                                <div className="item" >
                                    <img src={postgreSql} alt="PostgreSQL" />
                                    <h5>PostgreSQL</h5>
                                </div>
                                <div className="item" >
                                    <img src={sequelizeIcon} alt="Sequelize" />
                                    <h5>Sequelize</h5>
                                </div>
                                <div className="item" >
                                    <img src={gitIcon} alt="Git" />
                                    <h5>Git</h5>
                                </div>
                                <div className="item" >
                                    <img src={gitlabIcon} alt="Gitlab" />
                                    <h5>Gitlab</h5>
                                </div>
                                <div className="item" >
                                    <img src={githubIcon} alt="Github" />
                                    <h5>Github</h5>
                                </div>
                                <div className="item" >
                                    <img src={apacheIcon} alt="Apache" />
                                    <h5>Apache2</h5>
                                </div>
                                <div className="item" >
                                    <img src={nginxIcon} alt="Nginx" />
                                    <h5>Nginx</h5>
                                </div>
                                <div className="item" >
                                    <img src={vercelIcon} alt="Vercel" />
                                    <h5>Vercel</h5>
                                </div>
                                <div className="item" >
                                    <img src={awsIcon} alt="Aws" />
                                    <h5>AWS</h5>
                                </div>
                                <div className="item" >
                                    <img src={herokuIcon} alt="Heroku" />
                                    <h5>Heroku</h5>
                                </div>
                                <div className="item" >
                                    <img src={chartjsIcon} alt="Chartjs" />
                                    <h5>Chartjs</h5>
                                </div>
                                <div className="item" >
                                    <img src={reduxIcon} alt="Redux" />
                                    <h5>Redux</h5>
                                </div>
                                <div className="item" >
                                    <img src={jestIcon} alt="Jest" />
                                    <h5>Jest</h5>
                                </div>
                                <div className="item" >
                                    <img src={jwtIcon} alt="Jwt Auth" />
                                    <h5>Jwt Auth</h5>
                                </div>
                                <div className="item" >
                                    <img src={talwindIcon} alt="Talwind" />
                                    <h5>Talwind</h5>
                                </div>
                                <div className="item" >
                                    <img src={bootstrapIcon} alt="Bootstrap" />
                                    <h5>Bootstrap</h5>
                                </div>
                                <div className="item" >
                                    <img src={muiIcon} alt="Mui" />
                                    <h5>Mui</h5>
                                </div>
                                <div className="item" >
                                    <img src={graphqlIcon} alt="Graphql" />
                                    <h5>GraphQL</h5>
                                </div>
                                <div className="item" >
                                    <img src={figmaIcon} alt="Figma" />
                                    <h5>Figma</h5>
                                </div>
                                <div className="item" >
                                    <img src={photoshopIcon} alt="Adobe photoshop" />
                                    <h5>Adobe Photoshop</h5>
                                </div>
                                <div className="item" >
                                    <img src={aiIcon} alt="Ilustrator" />
                                    <h5>Adobe Ilustrator</h5>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
            <img className="background-image-left" src={colorSharp} />
        </section>
    );
};

export default Skills;
